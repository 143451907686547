import {
    Livewire,
    Alpine,
} from "../../vendor/livewire/livewire/dist/livewire.esm";

// Plugins
import ui from "@alpinejs/ui";

// Components
import "../../vendor/wire-elements/pro/resources/js/overlay-component.js";
// import "../../vendor/wire-elements/pro/resources/js/spotlight-component.js";

Alpine.plugin(ui);

Livewire.start();
